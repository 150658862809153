const ChairishContext = window.chairisher.context;

/**
 * An interface that provides documentation and access to context variables related to trade members
 */
const TradeContext = {
    /**
     * @returns {string|undefined} The URL to use when redeeming trade rewards
     */
    getTradeRewardsRedemptionUrl() {
        return ChairishContext.TRADE_REWARD_REDEMPTION_URL;
    },

    getTradeInsuranceProgramBenefits() {
        return ChairishContext.TRADE_INSURANCE_PROGRAM_BENEFITS;
    },
};

export default TradeContext;
